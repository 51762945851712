// React
import { useContext } from "react";
// MainStem - UI
import { Button } from "@mainstem/react-mainstem";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faPaperclipVertical, faTrash } from "@fortawesome/pro-light-svg-icons";
// Global - Context
import { AppContext } from "context";
// Local - Types
import { ISideKickFooter } from "./types";

const SideKickFooter: React.FC<ISideKickFooter> = ({
  loadingConversation,
  loadingScrape,
  onShowScrape,
}: ISideKickFooter) => {
  // Global - Context
  const { clearMessages } = useContext(AppContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          marginTop: 5,
        }}
      >
        <div>
          <Button
            color="primary"
            loading={loadingConversation}
            small
            tooltip="Ask MainStem SideKick a question"
          >
            <FontAwesomeIcon icon={faMessage} />
          </Button>
        </div>
        <div>
          <Button
            color="primary"
            loading={loadingScrape}
            onClick={() => {
              onShowScrape();
            }}
            small
            tooltip="Paste A URL"
          >
            <FontAwesomeIcon icon={faPaperclipVertical} />
          </Button>
        </div>
        <div>
          <Button
            color="primary"
            onClick={() => {
              clearMessages();
            }}
            small
            tooltip="Clear Messages"
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </div>
    </>
  );
};

export { SideKickFooter };
