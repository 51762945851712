import styled from "styled-components";

export const SCPageWrapperSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const SCPageWrapperSidebarMessages = styled.div`
  flex-grow: 1;
  max-height: calc(100vh - 240px);
  overflow-y: scroll;
  padding: 15px;

  ::-webkit-scrollbar {
    width: 4px;
    background: #ecebeb;
    box-shadow: none;
    z-index: 9999;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b0adad;
    height: 8px;
    border-radius: 8px;
    box-shadow: none;
  }
`;

export const SCPageWrapperSidebarMessage = styled.div`
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 10px;
`;
