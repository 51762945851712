// Local - Styles
import { SC_SideKickTitle } from "./styles";

const SideKickTitle: React.FC = () => {
  return (
    <>
      <SC_SideKickTitle>MainStem SideKick™</SC_SideKickTitle>
    </>
  );
};

export { SideKickTitle };
