import styled from "styled-components";

export const SCSideKickInputContainer = styled.div`
  flex: 0;
  height: 150px;

  input {
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const SCSideKickInputLine = styled.div`
  background: radial-gradient(
    106.04% 106.06% at 100.1% 90.19%,
    #0f6cbd 33.63%,
    #8dddd8 100%
  );
  height: 5px;
  width: 100%;
  margin-top: -16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
