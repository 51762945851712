// React
import { useContext, useEffect, useState } from 'react'
// MainStem - UI
import {
  Button,
  FormattedCurrency,
  InputText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersSideKickAIConversationAPIResponse,
  MainStemAPIControllersSideKickAIOptionalityAPIRequestProductDetails
} from 'api'
import { faChevronDown, faChevronUp, faPaperclipVertical } from '@fortawesome/pro-light-svg-icons'
// Global - Context
import { AppContext } from 'context'
import { IMessage } from 'context/types'
// Local - Components
import { SideKickContent, SideKickFooter, SideKickInput, SideKickTitle } from './components'
// Local - Styles
import {
  SC_SideKick,
  SC_SideKickButtonClose,
  SC_SideKickButtonOpen,
  SCSideKickContainer,
  SCSideKickContainerInputs,
  SCSideKickContainerMessages,
  SCSideKickContainerRespond,
  SCSideKickContainerProductOptions
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SideKickCeylon: React.FC = () => {
  // Global - Context
  const { addMessages, setShowSideKick, showSideKick, showAcceptContinue } = useContext(AppContext)
  // View State - Loading
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingConversation, setLoadingConversation] = useState<boolean>(false)
  const [loadingOptionality, setLoadingOptionality] = useState<boolean>(false)
  const [loadingScrape, setLoadingScrape] = useState<boolean>(false)
  // View State - Data
  const [messagesToAdd, setMessagesToAdd] = useState<IMessage[]>([])
  // View State - Inputs
  const [url, setURL] = useState<string>('')
  // View State - Modals
  const [showModalScrape, setShowModalScrape] = useState<boolean>(false)

  // const [showAcceptContinue, setShowAcceptContinue] = useState<boolean>(false)

  const addMessageToAdd = (messagesToAdd: Array<IMessage>) => {
    setMessagesToAdd([...messagesToAdd])
  }

  const getConversation = (message: string) => {
    setLoading(true)

    const usersMessage = message

    /**
     * Regular expression to match URLs in a string.
     */
    const urlRegex = /https?:\/\/[^\s]+/g
    /**
     * Checks if the user's message contains any URLs, extracts each URL,
     * and processes it by calling the `getScrape` function.
     *
     * @param usersMessage - The message from the user that may contain URLs.
     * @returns void
     */
    // Check if the user's message contains any URLs
    if (usersMessage.match(urlRegex)) {
      // Extract all matched URLs from the user's message
      const usersFilterUrlMessages = usersMessage.match(urlRegex)
      // If URLs are found, process each URL
      if (usersFilterUrlMessages) {
        usersFilterUrlMessages.forEach((usersFilterUrlMessage) => {
          getScrape(usersFilterUrlMessage)
        })
      }
      return
    }

    setLoadingConversation(true)

    addMessageToAdd([
      {
        type: 'user',
        message: usersMessage
      }
    ])

    // Prepare the API request
    const apiRequest = {
      message: usersMessage
    }

    // Call the MainStem API
    MainStemApi.api
      .aiConversation(apiRequest)
      .then((apiResponse) => {
        addMessageToAdd([
          {
            type: 'bot',
            message: updateCitationLinks(apiResponse.data)
          }
        ])
      })
      .catch(() => {
        toast.error('An error occurred while processing your request.')
      })
      .finally(() => {
        setLoadingConversation(false)
        setLoading(false)
      })
  }

  const updateCitationLinks = (response: MainStemAPIControllersSideKickAIConversationAPIResponse) => {
    let newMessage = response.message

    if (newMessage.indexOf('[doc1]') > -1) {
      newMessage = newMessage.replace('[doc1]', `[doc1](${response.citations[0].url})`)
    }

    if (newMessage.indexOf('[doc2]') > -1) {
      newMessage = newMessage.replace('[doc2]', `[doc2](${response.citations[1].url})`)
    }
    
    if (newMessage.indexOf('[doc3]') > -1) {
      newMessage = newMessage.replace('[doc3]', `[doc3](${response.citations[2].url})`)
    }

    if (newMessage.indexOf('[doc4]') > -1) {
      newMessage = newMessage.replace('[doc4]', `[doc4](${response.citations[3].url})`)
    }

    return newMessage
  }

  const getScrape = (urlToCheck?: string) => {
    setLoading(true)
    setLoadingScrape(true)

    if (urlToCheck) {
      addMessageToAdd([
        {
          type: 'user',
          message: (
            <>
              <div style={{ display: 'flex', gap: '5px' }}>
                <div>
                  <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
                    <rect fill='white' height='24' rx='12' width='24' />
                    <path
                      d='M18.0184 12.7736C19.3272 11.4522 19.3272 9.3121 18.0184 7.99064C16.8601 6.8212 15.0347 6.66917 13.7027 7.63045L13.6656 7.65618C13.332 7.89708 13.2556 8.36486 13.4942 8.69932C13.7328 9.03378 14.1961 9.1133 14.5273 8.8724L14.5644 8.84667C15.308 8.31106 16.325 8.39526 16.969 9.04781C17.6987 9.78456 17.6987 10.9774 16.969 11.7141L14.3698 14.343C13.6401 15.0798 12.4587 15.0798 11.729 14.343C11.0827 13.6905 10.9993 12.6637 11.5297 11.9153L11.5552 11.8779C11.7938 11.5411 11.7151 11.0733 11.3838 10.8347C11.0525 10.5962 10.5869 10.6733 10.3506 11.0078L10.3251 11.0452C9.37073 12.3877 9.5213 14.2308 10.6796 15.4002C11.9884 16.7217 14.1081 16.7217 15.4169 15.4002L18.0184 12.7736ZM5.98163 12.2264C4.67279 13.5478 4.67279 15.6879 5.98163 17.0094C7.1399 18.1788 8.96534 18.3308 10.2973 17.3696L10.3344 17.3438C10.668 17.1029 10.7444 16.6351 10.5058 16.3007C10.2672 15.9662 9.80392 15.8867 9.47266 16.1276L9.43559 16.1533C8.69198 16.6889 7.67502 16.6047 7.03103 15.9522C6.30132 15.2131 6.30132 14.0203 7.03103 13.2835L9.63018 10.657C10.3599 9.92021 11.5413 9.92021 12.271 10.657C12.9173 11.3095 13.0007 12.3363 12.4703 13.0871L12.4448 13.1245C12.2062 13.4613 12.2849 13.9291 12.6162 14.1676C12.9475 14.4062 13.4131 14.329 13.6494 13.9945L13.6749 13.9571C14.6293 12.6123 14.4787 10.7692 13.3204 9.59979C12.0116 8.27832 9.89195 8.27832 8.58311 9.59979L5.98163 12.2264Z'
                      fill='#252831'
                    />
                  </svg>
                </div>
                <div>{urlToCheck}</div>
              </div>
            </>
          )
        },
        {
          type: 'bot',
          message: <>Got your URL! Give me a moment to use my AI to scrape the page for product details.</>
        }
      ])
    }

    const apiRequest = {
      url: urlToCheck || url
    }

    MainStemApi.api
      .aiScrape(apiRequest)
      .then(async (apiResponse) => {
        if (apiResponse.data.product) {
          addMessageToAdd([
            {
              type: 'bot',
              message: (
                <>
                  <div style={{ wordWrap: 'break-word', maxWidth: '84%' }}>
                    So I took a look at the URL&nbsp;
                    <a href='${url}' target='_blank'>
                      {url}
                    </a>
                    &nbsp;and found a product named {apiResponse.data.product.name}.
                    {apiResponse.data.product.mainImage?.url && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '12px',
                          padding: '32px 12px 12px',
                          marginTop: '20px',
                          borderTop: '1px solid #EFF0F2'
                        }}
                      >
                        <img
                          src={apiResponse.data.product.mainImage.url}
                          style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'contain',
                            border: '1px solid #EFF0F2',
                            borderRadius: '4px'
                          }}
                        />
                        <SCSideKickContainerRespond>
                          <h2>{apiResponse.data.product.name}</h2>
                          <p style={{ margin: '5px 0px 10px' }}>SKU Model: {apiResponse.data.product.sku}</p>
                          <strong>
                            {apiResponse.data.product.price ? (
                              <FormattedCurrency value={apiResponse.data.product.price} />
                            ) : (
                              <i>N/A</i>
                            )}
                          </strong>
                        </SCSideKickContainerRespond>
                      </div>
                    )}
                  </div>
                </>
              )
            },
            {
              type: 'bot',
              message: (
                <>
                  I found some product options for you on MainStem! Do you want to check them out?
                  <SCSideKickContainerProductOptions>
                    <Button
                      block={true}
                      color='primary'
                      loading={loadingOptionality}
                      onClick={() => {
                        addMessageToAdd([
                          {
                            type: 'user',
                            message: 'Maybe later'
                          },
                          {
                            type: 'bot',
                            message: (
                              <>
                                Of course. Do you need help with anything else?
                                <SCSideKickContainerProductOptions>
                                  <Button
                                    block={true}
                                    color='primary'
                                    loading={loadingOptionality}
                                    onClick={() => {
                                      getConversation('Contact Support')
                                    }}
                                    style={{ width: 'auto' }}
                                  >
                                    Contact Support
                                  </Button>
                                  <Button
                                    block={true}
                                    color='primary'
                                    loading={loadingOptionality}
                                    onClick={() => {
                                      addMessageToAdd([
                                        {
                                          type: 'user',
                                          message: 'I’m good, thanks!'
                                        },
                                        {
                                          type: 'bot',
                                          message: (
                                            <>
                                              Glad to be of help. Thank you for using Sidekick! If you need any
                                              assistance, feel free to use Sidekick. If not, you can contact support and
                                              we’ll connect you with our customer service as soon as possible.
                                            </>
                                          )
                                        }
                                      ])
                                    }}
                                    style={{ width: 'auto' }}
                                  >
                                    I’m good, thanks!
                                  </Button>
                                </SCSideKickContainerProductOptions>
                              </>
                            )
                          }
                        ])
                      }}
                      style={{ width: 'auto' }}
                    >
                      Maybe Later
                    </Button>
                    <Button
                      block={true}
                      color='primary'
                      loading={loadingOptionality}
                      onClick={() => {
                        if (apiResponse.data.product?.name) {
                          getOptionality({
                            name: apiResponse.data.product.name,
                            sku: apiResponse.data.product.sku
                          })
                        }
                      }}
                      style={{ width: 'auto' }}
                    >
                      View Other Options
                    </Button>
                    
                    <Button
                      block={true}
                      color='primary'
                      loading={loadingOptionality}
                      onClick={() => {
                        if (apiResponse.data.product?.name) {
                          getOptionality({
                            name: apiResponse.data.product.name,
                            sku: apiResponse.data.product.sku
                          })
                        }
                      }}
                      style={{ width: 'auto' }}
                    >
                      Import Into MainStem
                    </Button>
                  </SCSideKickContainerProductOptions>
                </>
              )
            }
          ])
          setShowModalScrape(false)
        }
      })
      .catch(() => {
        toast.error('An error occurred while processing your request.')
      })
      .finally(() => {
        setLoadingScrape(false)
        setLoading(false)
      })
  }

  const getOptionality = (product: MainStemAPIControllersSideKickAIOptionalityAPIRequestProductDetails) => {
    setLoading(true)
    setLoadingOptionality(true)

    const apiRequest = {
      product
    }

    MainStemApi.api
      .aiOptionality(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.products) {
          addMessageToAdd([
            { type: 'user', message: 'Can you show me more product options?' },
            {
              type: 'bot',
              message: (
                <div style={{ maxWidth: '84%' }}>
                  <div>I found some product options for you on MainStem! Check them out below:</div>
                  <br />
                  <div>
                    {apiResponse.data.products.map((product, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            marginBottom: index + 1 < apiResponse.data.products.length ? 20 : 0
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexGrow: 1,
                              flexDirection: 'row'
                            }}
                          >
                            <div style={{ display: 'flex', flexGrow: 1 }}>
                              <img
                                src={
                                  product.marketplace === 'Amazon'
                                    ? theme.logos.amazonBusiness
                                    : product.marketplace === 'Staples'
                                      ? 'https://mainstem.blob.core.windows.net/files/e2b51cd5-d95f-48a5-9630-296a75cab5eb.png'
                                      : theme.logos.mainstem.color
                                }
                                style={{ height: '20px' }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 10,
                              borderRadius: '8px',
                              background: '#FAFAFB',
                              padding: '10px'
                            }}
                          >
                            <div>
                              <img
                                src={product.image || ''}
                                style={{
                                  height: '80px',
                                  width: '80px',
                                  objectFit: 'cover',
                                  border: '1px solid #EFF0F2'
                                }}
                              />
                            </div>
                            <div style={{ flexGrow: 1 }}>
                              <div
                                style={{
                                  height: 'auto',
                                  overflow: 'hidden',
                                  color: '#252831',
                                  fontFamily: 'Poppins',
                                  fontSize: '14px'
                                }}
                              >
                                {product.name}
                              </div>
                              <div>
                                <FormattedCurrency value={product.price} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }
          ])
        }
      })
      .catch(() => {
        toast.error('An error occurred while processing your request.')
      })
      .finally(() => {
        setLoadingOptionality(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (messagesToAdd.length > 0) {
      addMessages(messagesToAdd)
      setMessagesToAdd([])
    }
  }, [messagesToAdd, addMessages])

  return (
    <>
      {showSideKick ? (
        <>
          <SC_SideKickButtonClose
            onClick={() => {
              setShowSideKick(false)
            }}
          >
            Close&nbsp;
            <FontAwesomeIcon icon={faChevronDown} />
          </SC_SideKickButtonClose>
        </>
      ) : (
        <>
          <SC_SideKickButtonOpen
            onClick={() => {
              setShowSideKick(true)
            }}
          >
            Launch SideKick &nbsp;
            <FontAwesomeIcon icon={faChevronUp} style={{ height: '18px', width: '15px' }} />
          </SC_SideKickButtonOpen>
        </>
      )}

      {showSideKick && (
        <SC_SideKick>
          <SideKickTitle />
          <SCSideKickContainer>
            <SCSideKickContainerMessages>
              <SideKickContent
                loading={loading}
                onGetConversation={(message) => {
                  getConversation(message)
                }}
                onShowScrape={() => {
                  setShowModalScrape(true)
                }}
              />
            </SCSideKickContainerMessages>
            {showAcceptContinue && (
              <SCSideKickContainerInputs>
                <SideKickInput
                  onGetConversation={(message) => {
                    getConversation(message)
                  }}
                />
                <SideKickFooter
                  loadingConversation={loadingConversation}
                  loadingScrape={loadingScrape}
                  onShowScrape={() => {
                    setShowModalScrape(true)
                  }}
                />
              </SCSideKickContainerInputs>
            )}
          </SCSideKickContainer>
        </SC_SideKick>
      )}
      {showModalScrape && (
        <Modal onClose={() => setShowModalScrape(false)}>
          <ModalHeader>Give MainStem SideKick™ A URL</ModalHeader>
          <ModalBody>
            <InputText
              onChange={(newValue) => {
                setURL(newValue)
              }}
              onEnter={() => {
                getScrape()
              }}
              placeholder='Enter URL here: https://www.example.com'
              value={url}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              block
              color='fancy'
              icon={faPaperclipVertical}
              loading={loadingScrape}
              onClick={() => {
                getScrape()
              }}
            >
              Submit URL &amp; Use MainStem SideKick™ AI To Analyze...
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export { SideKickCeylon }
