// React
import { useContext, useEffect, useRef } from "react";
// MainStem - UI
import { Button, Loader } from "@mainstem/react-mainstem";
// Icons
import {
  faMessage,
  faPaperclipVertical,
} from "@fortawesome/pro-light-svg-icons";
// Global - Context
import { AppContext } from "context";
// Local - Styles
import {
  SCPageWrapperSidebarContent,
  SCPageWrapperSidebarMessage,
  SCPageWrapperSidebarMessages,
} from "./styles";
// Local - Types
import { ISideKickContent } from "./types";

const SideKickContent: React.FC<ISideKickContent> = ({
  loading,
  onShowScrape,
}: ISideKickContent) => {
  // Global - Context
  const { messages } = useContext(AppContext);
  // Refs
  const messagesViewRef = useRef<HTMLDivElement>(null);

  /**
   * Anytime "loading" or "messages" change, scroll to the bottom of the messages view.
   */
  useEffect(() => {
    if (messagesViewRef.current) {
      messagesViewRef.current.scrollTop = messagesViewRef.current.scrollHeight;
    }
  }, [loading, messages]);

  return (
    <>
      <SCPageWrapperSidebarContent>
        <SCPageWrapperSidebarMessages ref={messagesViewRef}>
          <SCPageWrapperSidebarMessage>
            Hello! I am MainStem SideKick. I am here to help you with any
            questions you may have. Please feel free to ask me anything.
            <br />
            <br />I will also provide you with helpful tips and tricks to make
            your experience on MainStem even better.
          </SCPageWrapperSidebarMessage>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <div>
              <Button color="fancy" icon={faMessage}>
                <span>Ask MainStem SideKick a question</span>
              </Button>
            </div>
            <div>
              <Button
                color="fancy"
                icon={faPaperclipVertical}
                onClick={() => {
                  onShowScrape();
                }}
              >
                <span>Give MainStem SideKick A URL</span>
              </Button>
            </div>
          </div>
          {messages.map((message, index) => {
            return (
              <SCPageWrapperSidebarMessage
                key={index}
                style={{
                  backgroundColor:
                    message.type === "bot" ? "#FFFFFF" : "#edf5fd",
                  border: "1px solid #e8e8e8",
                  padding: 10,
                  margin: "10px 0px",
                }}
              >
                {message.message}
              </SCPageWrapperSidebarMessage>
            );
          })}
          {loading && (
            <>
              <SCPageWrapperSidebarMessage>
                <Loader size="sm" />
              </SCPageWrapperSidebarMessage>
            </>
          )}
        </SCPageWrapperSidebarMessages>
      </SCPageWrapperSidebarContent>
    </>
  );
};

export { SideKickContent };
