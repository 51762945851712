// React
import { useContext, useEffect, useState } from 'react'
// MainStem - UI
import {
  Button,
  FormattedCurrency,
  InputText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi, MainStemAPIControllersSideKickAIOptionalityAPIRequestProductDetails } from 'api'
import { faChevronDown, faChevronUp, faCloudDownloadAlt, faPaperclipVertical } from '@fortawesome/pro-light-svg-icons'
// Global - Context
import { AppContext } from 'context'
import { IMessage } from 'context/types'
// Local - Components
import { SideKickContent, SideKickFooter, SideKickInput, SideKickTitle } from './components'
// Local - Styles
import {
  SC_SideKick,
  SC_SideKickButtonClose,
  SC_SideKickButtonOpen,
  SCSideKickContainer,
  SCSideKickContainerInputs,
  SCSideKickContainerMessages
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SideKick: React.FC = () => {
  // Global - Context
  const { addMessages, setShowSideKick, showSideKick } = useContext(AppContext)
  // View State - Loading
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingConversation, setLoadingConversation] = useState<boolean>(false)
  const [loadingOptionality, setLoadingOptionality] = useState<boolean>(false)
  const [loadingScrape, setLoadingScrape] = useState<boolean>(false)
  // View State - Data
  const [messagesToAdd, setMessagesToAdd] = useState<IMessage[]>([])
  // View State - Inputs
  const [url, setURL] = useState<string>('')
  // View State - Modals
  const [showModalScrape, setShowModalScrape] = useState<boolean>(false)

  const addMessageToAdd = (messagesToAdd: Array<IMessage>) => {
    setMessagesToAdd([...messagesToAdd])
  }

  const getConversation = (message: string) => {
    setLoading(true)

    const usersMessage = message

    if (usersMessage.startsWith('http')) {
      getScrape(usersMessage)
      return
    }

    setLoadingConversation(true)

    addMessageToAdd([
      {
        type: 'user',
        message: usersMessage
      }
    ])

    // Prepare the API request
    const apiRequest = {
      message: usersMessage
    }

    // Call the MainStem API
    MainStemApi.api
      .aiConversation(apiRequest)
      .then((apiResponse) => {
        addMessageToAdd([
          {
            type: 'bot',
            message: <div dangerouslySetInnerHTML={{ __html: apiResponse.data.message }} />
          }
        ])
      })
      .catch(() => {
        toast.error('An error occurred while processing your request.')
      })
      .finally(() => {
        setLoadingConversation(false)
        setLoading(false)
      })
  }

  const getScrape = (urlToCheck?: string) => {
    setLoading(true)
    setLoadingScrape(true)

    if (urlToCheck) {
      addMessageToAdd([
        {
          type: 'user',
          message: urlToCheck
        },
        {
          type: 'bot',
          message: <>Got your URL! Give me a moment to use my AI to scrape the page for product details.</>
        }
      ])
    }

    const apiRequest = {
      url: urlToCheck || url
    }

    MainStemApi.api
      .aiScrape(apiRequest)
      .then(async (apiResponse) => {
        if (apiResponse.data.product) {
          addMessageToAdd([
            {
              type: 'bot',
              message: (
                <>
                  <div style={{ wordWrap: 'break-word' }}>
                    So I took at look at the URL&nbsp;
                    <a href='${url}' target='_blank'>
                      {url}
                    </a>
                    &nbsp;and found a product named <strong>{apiResponse.data.product.name}</strong>. Looks like it has
                    a sku of <strong>{apiResponse.data.product.sku}</strong> and costs{' '}
                    <strong>
                      {apiResponse.data.product.price ? (
                        <FormattedCurrency value={apiResponse.data.product.price} />
                      ) : (
                        <i>N/A</i>
                      )}
                    </strong>
                    {apiResponse.data.product.mainImage?.url && (
                      <div>
                        <img src={apiResponse.data.product.mainImage.url} style={{ width: '100%' }} />
                      </div>
                    )}
                    .
                  </div>
                </>
              )
            },
            {
              type: 'bot',
              message: (
                <>
                  <Button
                    block={true}
                    color='fancy-gray'
                    disabled={false}
                    icon={faCloudDownloadAlt}
                    onClick={() => {
                      // TODO
                    }}
                  >
                    Import Product Into MainStem
                  </Button>
                </>
              )
            },
            {
              type: 'bot',
              message: (
                <>
                  <Button
                    block={true}
                    color='primary'
                    icon={theme.icons.mainstem.products}
                    loading={loadingOptionality}
                    onClick={() => {
                      if (apiResponse.data.product?.name) {
                        getOptionality({
                          name: apiResponse.data.product.name,
                          sku: apiResponse.data.product.sku
                        })
                      }
                    }}
                  >
                    Get Product Options
                  </Button>
                </>
              )
            }
          ])
          setShowModalScrape(false)
        }
      })
      .catch(() => {
        toast.error('An error occurred while processing your request.')
      })
      .finally(() => {
        setLoadingScrape(false)
        setLoading(false)
      })
  }

  const getOptionality = (product: MainStemAPIControllersSideKickAIOptionalityAPIRequestProductDetails) => {
    setLoading(true)
    setLoadingOptionality(true)

    const apiRequest = {
      product
    }

    MainStemApi.api
      .aiOptionality(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.products) {
          addMessageToAdd([
            {
              type: 'bot',
              message: (
                <>
                  <div>I found some product options for you on MainStem! Check them out below:</div>
                  <br />
                  <div>
                    {apiResponse.data.products.map((product, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                            marginBottom: index + 1 < apiResponse.data.products.length ? 20 : 0
                          }}
                        >
                          <div
                            style={{
                              borderBottom: '1px solid #e8e8e8',
                              display: 'flex',
                              flexGrow: 1,
                              flexDirection: 'row'
                            }}
                          >
                            <div style={{ display: 'flex', flexGrow: 1 }}>
                              <img
                                src={
                                  product.marketplace === 'Amazon'
                                    ? theme.logos.amazonBusiness
                                    : product.marketplace === 'Staples'
                                      ? 'https://mainstem.blob.core.windows.net/files/e2b51cd5-d95f-48a5-9630-296a75cab5eb.png'
                                      : theme.logos.mainstem.color
                                }
                                style={{ height: 20 }}
                              />
                            </div>
                            <div>
                              <FormattedCurrency value={product.price} />
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 10
                            }}
                          >
                            <div>
                              <img src={product.image || ''} style={{ height: 20 }} />
                            </div>
                            <div style={{ flexGrow: 1 }}>
                              <div style={{ height: 30, overflow: 'hidden' }}>{product.name}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )
            }
          ])
        }
      })
      .catch(() => {
        toast.error('An error occurred while processing your request.')
      })
      .finally(() => {
        setLoadingOptionality(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (messagesToAdd.length > 0) {
      addMessages(messagesToAdd)
      setMessagesToAdd([])
    }
  }, [messagesToAdd, addMessages])

  return (
    <>
      {showSideKick ? (
        <>
          <SC_SideKickButtonClose
            onClick={() => {
              setShowSideKick(false)
            }}
          >
            Close&nbsp;
            <FontAwesomeIcon icon={faChevronDown} />
          </SC_SideKickButtonClose>
        </>
      ) : (
        <>
          <SC_SideKickButtonOpen
            onClick={() => {
              setShowSideKick(true)
            }}
          >
            Launch SideKick&nbsp;
            <FontAwesomeIcon icon={faChevronUp} />
          </SC_SideKickButtonOpen>
        </>
      )}

      {showSideKick && (
        <SC_SideKick>
          <SideKickTitle />
          <SCSideKickContainer>
            <SCSideKickContainerMessages>
              <SideKickContent
                loading={loading}
                onShowScrape={() => {
                  setShowModalScrape(true)
                }}
              />
            </SCSideKickContainerMessages>
            <SCSideKickContainerInputs>
              <SideKickInput
                onGetConversation={(message) => {
                  getConversation(message)
                }}
              />
              <SideKickFooter
                loadingConversation={loadingConversation}
                loadingScrape={loadingScrape}
                onShowScrape={() => {
                  setShowModalScrape(true)
                }}
              />
            </SCSideKickContainerInputs>
          </SCSideKickContainer>
        </SC_SideKick>
      )}
      {showModalScrape && (
        <Modal onClose={() => setShowModalScrape(false)}>
          <ModalHeader>Give MainStem SideKick™ A URL</ModalHeader>
          <ModalBody>
            <InputText
              onChange={(newValue) => {
                setURL(newValue)
              }}
              onEnter={() => {
                getScrape()
              }}
              placeholder='Enter URL here: https://www.example.com'
              value={url}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              block
              color='fancy'
              icon={faPaperclipVertical}
              loading={loadingScrape}
              onClick={() => {
                getScrape()
              }}
            >
              Submit URL &amp; Use MainStem SideKick™ AI To Analyze...
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export { SideKick }
