/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MainStemAPIControllersSideKickAIConversationAPIRequest {
  message: string
}

export interface MainStemAPIControllersSideKickAIConversationAPIResponse {
  citations: MainStemAPIControllersSideKickAIConversationAPIResponseCitationDetails[]
  message: string
}

export interface MainStemAPIControllersSideKickAIConversationAPIResponseCitationDetails {
  content?: string
  title?: string
  url?: string
}

export interface MainStemAPIControllersSideKickAIOptionalityAPIRequest {
  product: MainStemAPIControllersSideKickAIOptionalityAPIRequestProductDetails
}

export interface MainStemAPIControllersSideKickAIOptionalityAPIRequestProductDetails {
  name: string
  sku?: string
}

export interface MainStemAPIControllersSideKickAIOptionalityAPIResponse {
  products: MainStemAPIControllersSideKickAIOptionalityAPIResponseMainStemProductDetails[]
}

export interface MainStemAPIControllersSideKickAIOptionalityAPIResponseMainStemProductDetails {
  id: string
  image?: string
  marketplace: 'MainStem' | 'Amazon' | 'Staples'
  name: string
  /** @format double */
  price: number
}

export interface MainStemAPIControllersSideKickScrapeAPIRequest {
  url: string
}

export interface MainStemAPIControllersSideKickScrapeAPIResponse {
  product?: MainStemAPIControllersSideKickScrapeModelsZyteAPIResponseProductDetails
}

export interface MainStemAPIControllersSideKickScrapeModelsZyteAPIResponseProductDetails {
  mainImage?: MainStemAPIControllersSideKickScrapeModelsZyteAPIResponseProductDetailsImageDetails
  name?: string
  /** @format double */
  price?: number
  sku?: string
}

export interface MainStemAPIControllersSideKickScrapeModelsZyteAPIResponseProductDetailsImageDetails {
  url?: string
}

export interface MainStemAPIControllersSideKickAuthLoginAPIRequest {
  password: string
  username: string
}

export interface MainStemAPIControllersSideKickAuthLoginAPIResponse {
  token: string
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    const enterpriseToken = window.localStorage.getItem('mainstem_user_token')
    const sessionURL = window.localStorage.getItem('logrocket_session_url')
    const affiliateProgramUUID = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_AFFILIATE_ID
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://dev-api.mainstem.io',
      headers: {
        Authorization: `Bearer ${enterpriseToken || ''}`,
        'X-AffiliateProgramUUID': affiliateProgramUUID,
        'X-SessionURL': sessionURL,
        'X-Platform': 'Purchase Pro'
      }
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' }
      requestParams.headers.post = {}
      requestParams.headers.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    const enterpriseToken = window.localStorage.getItem('mainstem_user_token')
    const sessionURL = window.localStorage.getItem('logrocket_session_url')
    const affiliateProgramUUID = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_AFFILIATE_ID

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
        Authorization: `Bearer ${enterpriseToken || ''}`,
        'X-AffiliateProgramUUID': affiliateProgramUUID,
        'X-SessionURL': sessionURL,
        'X-Platform': 'Purchase Pro'
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title MainStem SideKick API V1
 * @version sidekick
 * @baseUrl https://dev-api.mainstem.io
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags AI
     * @name AiConversation
     * @request POST:/api/sidekick/ai/conversation
     */
    aiConversation: (APIRequest: MainStemAPIControllersSideKickAIConversationAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersSideKickAIConversationAPIResponse, any>({
        path: `/api/sidekick/ai/conversation`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AI
     * @name AiOptionality
     * @request POST:/api/sidekick/ai/optionality
     */
    aiOptionality: (Request: MainStemAPIControllersSideKickAIOptionalityAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersSideKickAIOptionalityAPIResponse, any>({
        path: `/api/sidekick/ai/optionality`,
        method: 'POST',
        body: Request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AI
     * @name AiScrape
     * @request POST:/api/sidekick/ai/scrape
     */
    aiScrape: (APIRequest: MainStemAPIControllersSideKickScrapeAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersSideKickScrapeAPIResponse, any>({
        path: `/api/sidekick/ai/scrape`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthLogin
     * @request POST:/api/sidekick/auth/login
     */
    authLogin: (APIRequest: MainStemAPIControllersSideKickAuthLoginAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersSideKickAuthLoginAPIResponse, any>({
        path: `/api/sidekick/auth/login`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
}

export const MainStemApi = new Api()
