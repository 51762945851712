import styled from 'styled-components'

export const SCSideKickInputContainer = styled.div`
  flex: 0;
  height: 150px;
  position: relative;
  input {
    border-radius: 100px;
  }
  svg {
    position: absolute;
    top:36.8%;
    right: 10px;
    transform: translateY(-49%);
    z-index: 99;
    cursor: pointer;
  }
`

export const SCSideKickInputLine = styled.div`
  background-color: #fff;
  height: 5px;
  width: 100%;
  margin-top: -16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 40px;
`
