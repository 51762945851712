import styled from 'styled-components'

export const SCPageWrapperSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`

export const SCPageWrapperSidebarMessages = styled.div`
  flex-grow: 1;
  max-height: calc(100vh - 198px);
  overflow-y: scroll;
  padding: 15px;

  ::-webkit-scrollbar {
    width: 4px;
    background: #ecebeb;
    box-shadow: none;
    z-index: 9999;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b0adad;
    height: 8px;
    border-radius: 8px;
    box-shadow: none;
  }
`

export const SCPageWrapperSidebarMessageMain = styled.div`
  background-color: #0000000;
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30%;
  width: 80%;
  margin: 0 auto;
  height: 100%;
  justify-content: center;
  span {
    color: #6327ad;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding-bottom: 12px;
  }
  p {
    color: #7d8495;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`
export const SCPageWrapperSidebarMessageInner = styled.div`
  background-color: #0000000;
  padding-top: 8%;
  border-top: 1px solid #eff0f2;
  margin-top: 4%;
`

export const SCPageWrapperSidebarMessage = styled.div`
  display: flex;
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 10px;
`

export const SCPageWrapperSidebarMessageContentWrapper = styled.div`
  p {
    margin-bottom: 0px;
  }
`

export const SCPageWrapperSidebarMessageDot = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: -8px;
    right: -7px;
  }
`

export const SCPageWrapperSidebarBotMessageOption = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  bottom: 25px;
  right: -50px;
  width: fit-content;
  color: #6327ad;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  svg:hover path {
    fill: #6327ad;
  }
`
export const SCPageWrapperSidebarUserMessageOption = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  bottom: 25px;
  left: 495px;
  width: fit-content;
  color: #6327ad;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  min-width: 76px;
  justify-content: flex-end;
  svg:hover path {
    fill: #6327ad;
  }
`
export const SCPageWrapperSidebarLoader = styled.div`
  height: 10px;
  margin: 10px 10px 10px 10px;
  aspect-ratio: 2.5;
  --_g: no-repeat radial-gradient(farthest-side, #5c657b73 90%, #0000);
  background: var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
  animation: l43 1s infinite linear;
  @keyframes l43 {
    0% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
    16.67% {
      background-position:
        calc(0 * 100% / 3) 0,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
    33.33% {
      background-position:
        calc(0 * 100% / 3) 100%,
        calc(1 * 100% / 3) 0,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
    50% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 100%,
        calc(2 * 100% / 3) 0,
        calc(3 * 100% / 3) 50%;
    }
    66.67% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 100%,
        calc(3 * 100% / 3) 0;
    }
    83.33% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 100%;
    }
    100% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
  }
`

export const SCPageWrapperSidebarPromptCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: fadeIn2 1s ease-in forwards;

}
`
export const SCPageWrapperSidebarPromptCardsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #6327ad;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  p {
    color: #7d8495;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  @keyframes wave {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
    15% {
      transform: rotate(15deg);
      opacity: 1;
    }
    30% {
      transform: rotate(-10deg);
      opacity: 1;
    }
    45% {
      transform: rotate(12deg);
      opacity: 1;
    }
    60% {
      transform: rotate(-8deg);
      opacity: 1;
    }
    75% {
      transform: rotate(10deg);
      opacity: 1;
    }
    100% {
      transform: rotate(0deg);
      opacity: 1;
    }
  }

  svg {
    display: inline-block;
    animation: wave 1s ease-in-out 2.5s;
    transform-origin: bottom center;
  }
`

export const SCPageWrapperSidebarPromptCardsContent = styled.div`
  display: flex;
    animation-delay: 3s;
     opacity: 0;
    animation: fadeIn2 1s ease-in forwards;
      animation-delay: 1s;
  p {
    color: #4a5162;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;

  }
  span {
    color: #0c0909;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    cursor: pointer;
  }
    @keyframes fadeIn2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const SCPageWrapperSidebarPromptCardsSingle = styled.div`
  display: flex;
  width: 250px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #dee0e5;
  margin: 5px;

  &:hover {
    background-color: #eff0f2;
    transition:;
  }
`

export const SCPageWrapperSidebarPromptCardsFooter = styled.div`
  p {
    color: #6327ad;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid rgba(99, 39, 173, 0.08);
    background: rgba(99, 39, 173, 0.07);
  }
  span {
    color: #7d8495;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: block;
    margin-top: 24px;
  }
`
export const SCPageSidebarMessageListItem = styled.div`
  display: block;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
  ul {
    list-style: none !important;
    margin: 0;
    padding: 0;
  }
  li {
    display: flex;
    gap: 12px;
    color: #7d8495;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
  }
  svg {
    margin-top: 4px;
  }
`
export const SCPageAcceptButton = styled.div`
  button {
    border-radius: 8px;
    background: #814ec2;
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`
export const SCPageWrapperAllSetMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  svg {
    margin-bottom: 12px;
  }
  p {
    color: #7d8495;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 50%;
  }
  opacity: 0;
  animation: fadeInOut 3s ease-in forwards;
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
export const SCPagePromptCardsSuggestions = styled.div`
  display: flex;
  width: 82%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  margin-top: 24px;
  p {
    cursor: pointer;
  }
`
export const SCPageWrapperSidebarMessageInitials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64c5a4;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 15px;
`
export const SCPageDisLikeButtonOption = styled.div`
  display: flex;
  width: 86%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: left;
  column-gap: 10px;
  margin-top: -15px;
  border-radius: 0px 6px 6px 6px;
  border: 1px solid #eff0f2;
  background: #fff;
  padding: 16px;
  color: #5c657b;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  p {
    cursor: pointer;
    color: #6327ad;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-radius: 6px;
    border: 1px solid rgba(99, 39, 173, 0.08);
    background: rgba(99, 39, 173, 0.07);
    margin-top: 5px;
    padding: 2px 8px;
  }
`
