import styled from 'styled-components'

export const SC_SideKickButtonOpen = styled.div`
  align-items: center;
  background-color: #814ec2;
  color: #fafafa;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: -94px;
  transform: rotate(-90deg);
  top: 50%;
  width: 230px;
  z-index: 20;
  border-radius: 0;
  padding: 10px 26px;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  gap: 5px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

export const SC_SideKickButtonClose = styled.div`
  align-items: center;
  background-color: #814ec2;
  color: #fafafa;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 608px;
  transform: rotate(-90deg);
  top: 50%;
  width: 140px;
  z-index: 100;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 26px;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #dee0e5;
  background: #fafafb;
`

export const SC_SideKick = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0px 0px 25px #4a4a57;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  right: 5px;
  top: 5px;
  width: 650px;
  z-index: 100;
  border-radius: 24px;
  border: 1px solid #eff0f2;
  background: #fafafb;
`

export const SCSideKickContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

export const SCSideKickContainerMessages = styled.div`
  flex-grow: 1;
`

export const SCSideKickContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

export const SCSideKickContainerRespond = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: #252831;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  p {
    color: #7d8495;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  strong {
    color: #7d8495;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`
export const SCSideKickContainerProductOptions = styled.div`
  display: flex;
  width: fit-content;
  gap: 15px;
  margin: 5px;

  button {
    background: rgba(129, 78, 194, 0.12);
    border: 1px solid rgba(129, 78, 194, 0.04);

    &:hover {
      background: #814ec2;
      border: 1px solid #814ec2;
      color: #fff;
    }
    &.selected {
      background: #814ec2;
      border: 1px solid #814ec2;
    }
  }
`
