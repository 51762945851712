// React
import { useState } from "react";
// MainStem - UI
import { InputText } from "@mainstem/react-mainstem";
// Local - Styles
import { SCSideKickInputContainer, SCSideKickInputLine } from "./styles";
// Local - Types
import { ISideKickInput } from "./types";

const SideKickInput: React.FC<ISideKickInput> = ({
  onGetConversation,
}: ISideKickInput) => {
  // View State
  const [messageText, setMessageText] = useState<string | undefined>(undefined);

  return (
    <>
      <SCSideKickInputContainer>
        <InputText
          onChange={(newValue) => {
            setMessageText(newValue);
          }}
          onEnter={() => {
            if (messageText) {
              onGetConversation(messageText);
              setMessageText(undefined);
            }
          }}
          placeholder="Ask a question or paste a URL..."
          value={messageText}
        />
        <SCSideKickInputLine />
      </SCSideKickInputContainer>
    </>
  );
};

export { SideKickInput };
