import styled from 'styled-components'

export const SC_SideKickButtonOpen = styled.div`
  align-items: center;
  background-color: #814ec2;
  color: #fafafa;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: -65px;
  transform: rotate(-90deg);
  top: 50%;
  width: 200px;
  z-index: 100;
`

export const SC_SideKickButtonClose = styled.div`
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  color: #222222;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 580px;
  transform: rotate(-90deg);
  top: 50%;
  width: 200px;
  z-index: 100;
`

export const SC_SideKick = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #312fa1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  right: 5px;
  top: 5px;
  width: 650px;
  z-index: 100;
`

export const SCSideKickContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

export const SCSideKickContainerMessages = styled.div`
  flex-grow: 1;
`

export const SCSideKickContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`
