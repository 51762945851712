// 3rd Party CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '@mainstem/mainstem-react-app/dist/index.css'
// Local CSS
import './assets/css/utilities.scss'
import './assets/css/layout.scss'
// React
import { useState } from 'react'
// Routing
import { BrowserRouter, Link } from 'react-router-dom'
// DEPRECATED : MainStem - UI
import { MainStemBase } from '@mainstem/mainstem-react-app'
// MainStem - UI
import { Button, theme, ToastContainer } from '@mainstem/react-mainstem'
// Icons
import { faChartLine } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { SideKick, SideKickCeylon } from 'components'
// Global - Context
import { AppContext } from 'context'
import { AppContextDetails, IMessage } from 'context/types'
// Global - Routes
import { Routes } from 'routes'

const Layout: React.FC = () => {
  // View State
  const [messages, setMessages] = useState<IMessage[]>([])
  const [showSideKick, setShowSideKick] = useState<boolean>(false)
  const [showMainStemSideKick, setShowMainStemSideKick] = useState<boolean>(false)
  const [showSideKickCeylon, setShowSideKickCeylon] = useState<boolean>(false)
  const [showMainStemSideKickCeylon, setShowMainStemSideKickCeylon] = useState<boolean>(false)
  const [showAcceptContinue, setShowAcceptContinue] = useState<boolean>(false)
  const [isUserLogin, setIsUserLogin] = useState<boolean>(false)

  const addMessages = (messagesToAdd: IMessage[]) => {
    async function addMessageAsync() {
      setMessages([...messages, ...messagesToAdd])
    }
    addMessageAsync()
  }

  const clearMessages = () => {
    setMessages([])
  }

  const contextValue = {
    addMessages,
    clearMessages,
    messages,
    setShowMainStemSideKick,
    setShowSideKick,
    showMainStemSideKick,
    showSideKick,
    setShowMainStemSideKickCeylon,
    setShowSideKickCeylon,
    showMainStemSideKickCeylon,
    showSideKickCeylon,
    showAcceptContinue,
    setShowAcceptContinue,
    setIsUserLogin,
    isUserLogin
  } as AppContextDetails

  const appSettings = {
    affiliateUUID: '',
    appName: 'Purchase Pro™',
    baseURL: '',
    checkedForInitialUser: true,
    forceLogin: false,
    impersonationComponent: undefined,
    publicizedRoutes: undefined,
    links: [
      {
        title: "MainStem's Version",
        to: '/mainstem',
        icon: faChartLine,
        bases: ['/mainstem/']
      },
      {
        title: "Ceylon's Version",
        to: '/ceylon',
        icon: faChartLine,
        bases: ['/ceylon/']
      }
    ],
    loggedInUser: {
      organization: {
        name: 'A-Z Company',
        logoURL: 'https://mainstem.blob.core.windows.net/files/8e7e93c8-7375-47f8-ad60-f8c96e292161.png'
      },
      username: 'notreal@mainstem.io'
    },
    logo: undefined,
    navTopAlertBar: <>This is an example layout of the MainStem app</>,
    navTopButtons: (
        <>
          <Link to='/mainstem'>
            <Button icon={theme.icons.view}>View MainStem's Version</Button>
          </Link>
          <Link to='/ceylon'>
            <Button icon={theme.icons.view}>View Ceylon's Version</Button>
          </Link>
        </>
    ),
    navTopCTA: <></>,
    navTopIcons: <></>,
    navTopMobile: (
        <>
          <div style={{ marginTop: 7, minWidth: '550px', width: '100%' }}>
            <></>
          </div>
        </>
    ),
    navTopSearch: <></>,
    navTopUserLinks: [],
    onLogin: () => {},
    onLogout: () => {},
    routes: <Routes />,
    showNavLeft: true,
    theme: 'ms-theme-2',
    userImage: 'https://mainstem.blob.core.windows.net/files/8e7e93c8-7375-47f8-ad60-f8c96e292161.png',
    userToken: undefined
  }

  return (
      <>
        <AppContext.Provider value={contextValue}>
          <BrowserRouter>
            <MainStemBase settings={appSettings} />
            <ToastContainer />
            {showMainStemSideKick && <SideKick />}
            {showMainStemSideKickCeylon && <SideKickCeylon />}
          </BrowserRouter>
        </AppContext.Provider>
      </>
  )
}

export default Layout
