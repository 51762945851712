import styled from "styled-components";

export const SC_SideKickTitle = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 8px 8px 0px 0px;
  color: #242424;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: sticky;
  text-align: center;
  width: 100%;
`;
