// React
import { Suspense, lazy } from "react";
// Routing
import { Redirect, Route, Switch } from "react-router-dom";
// MainStem - UI
import { Loader } from "@mainstem/react-mainstem";

// Lazy Loaded Pages
const lazyLoadedPages = {
  ceylon: lazy(() => import("pages/ceylon")),
  mainstem: lazy(() => import("pages/mainstem")),
};

export const Routes = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route exact path="/">
        <Redirect to={"/mainstem"} />
      </Route>
      {/* Original */}
      <Route exact path="/mainstem">
        <lazyLoadedPages.mainstem />
      </Route>
      {/* Ceylon */}
      <Route exact path="/ceylon">
        <lazyLoadedPages.ceylon />
      </Route>
    </Switch>
  </Suspense>
);
